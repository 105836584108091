.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}


ul.maketree, ul.maketree ul, ul.maketree li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
ul.maketree ul { padding-left: 0.3em; }
ul.maketree li {
  border-left: 1px dotted #000;
  padding-left: 13px;
  background: url(./assets/dotted.gif) scroll no-repeat 1px 0.8em;
}
ul.maketree li.last {
  border-left-width: 0px;
  padding-left: 14px;
  background: url(./assets/dottedangle.gif) scroll no-repeat left top;
}


table.product-list thead tr th {
  white-space:unset;
  padding: 0.1rem;
}

table.product-list tbody tr td {
  padding: 0.1rem;
}

table.baugroesse-list thead tr th {
  white-space:unset;
  padding: 0.75rem;
}

table.baugroesse-list tbody tr td {
  padding: 0;
}

.modal-details{
  min-width: 80%;
}


.checkbox-list label{
  padding-right: 10px;
}

.width-procent-5 {
  width: 5%;
}

.width-procent-10 {
  width: 10%;
}

.width-procent-15 {
  width: 15%;
}

.width-procent-20 {
  width: 20%;
}

.mt55 {
  margin-top: 55px !important;
}

.wrap td {
  white-space: break-spaces;
}

.nowrap {
  white-space: nowrap;
}

.inline-block {
  display: inline-block !important;
}

.navbar{
  background: #f5f6fa;
}

.nav-item .dropdown-toggle, .nav-link{
  color:#2d3742 !important;
  text-transform: uppercase;
}

@media (min-width: 992px) {
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 2.5rem;
}
}

.post-image { 
  width: 100%;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.custom-tooltip {
  max-width: 1000px;
  max-height: 500px;
  overflow: auto;
}

.__react_component_tooltip {
  padding: 10px !important;
}

.custom-mouseover-tooltip {
  z-index: 9999 !important;
}


.swiper-wrapper, .swiper-container, .swiper-slide, .slide-inner{
  max-height: 500px !important;
  min-height: unset !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mt0 {
  margin-top: 0px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pointer{
  cursor: pointer;
}

.error {
  color: red !important;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.error-div {
  border: 1px solid red;
}

.error-text {
  color: red;
}

.unset-height .modal-content{
  height: unset !important;
}

.navbarScrollingDropdown > .dropdown-menu.show{
  margin-left: 2.5em !important;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.form-control {
  border-radius:unset !important;
}